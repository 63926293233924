.map-export-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 64px;

  .map-export {
    width: 90%;
    padding: 24px;
    margin-bottom: 48px;

    .map-export-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      margin-top: 24px;

      .MuiTypography-body2 {
        margin-top: 8px;
        margin-bottom: 4px;
      }

      .map-export-map {
        padding: 24px;
        border-style: solid;
        border-color: #a6a6a6;
        border-radius: 8px;
        overflow: scroll;
        height: fit-content;
        width: fit-content;

        #mapboxMap {
          // width: 100%;
          // height: 90%;
          // width: 380px;
          // height: 380px;
        }

        .hidden-map {
          overflow: hidden;
          height: 0;
          width: 0;
          position: fixed;
        }
      }

      .map-export-sidebar {
        display: flex;
        flex-direction: column;
        margin-right: 16px;
        min-width: 240px;
      }
    }
  }
}

