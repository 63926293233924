.edit-organization-check-box-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.edit-organization-view {
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .edit-organization-paper {
    width: 90%;
    max-width: 1280px;
    padding: 24px;
    margin-bottom: 48px;
    max-width: 840px;

    .MuiTypography-h6 {
      margin-top: 48px;
      margin-bottom: 8px;

    }

    .MuiTypography-body2 {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .MuiTextField-root {
      width: 80%;
      max-width: 480px;
    }
    .edit-organization-content {
        max-width: 960px;
    }
    .edit-organization-bottom {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content:flex-end;
      max-width: 640px;
    }
  }
}
