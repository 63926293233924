
.organization-management {
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .organization-management-paper {
    width: 100%;
    padding: 24px;
    margin-bottom: 48px;
    max-width: 1280px;
    
    ::-webkit-scrollbar {
      display: none;
    }
    .organization-management-sub-title {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 32px;
      margin-bottom: 16px;

      .organization-management-sub-title-approved-emails {
        display: flex;
        flex-direction: row;
        align-items: center;

        .organization-management-sub-title-approved-emails-button {
          margin-left: 32px;
          margin-right: 32px;
        }
      }
    }

    .organization-management-add-new-user {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      margin-bottom: 16px;
      padding: 16px;

      .organization-management-add-new-user-header {

      }

      .organization-management-add-new-user-content {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .table {
      min-width: 640px;

      .table-title {
        th {
          font-weight: bold;
        }
      }
    }
  }
}
