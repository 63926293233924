.user-management {
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  .user-management-paper {
    width: 100%;
    padding: 24px;
    margin-bottom: 48px;
    max-width: 1280px;
  
    .user-management-sub-title {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 32px;
      margin-bottom: 16px;

      .user-management-sub-title-approved-emails {
        display: flex;
        flex-direction: row;
        align-items: center;

        .user-management-sub-title-approved-emails-button {
          margin-left: 32px;
          margin-right: 32px;
        }
      }
    }

    .user-management-add-new-user {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      margin-bottom: 16px;

      .user-management-add-new-user-paper {
        padding: 16px;
        // height: 180px;
        .user-management-add-new-user-header {
          margin-bottom: 16px;
        }
  
        .user-management-add-new-user-content {
          display: flex;
          flex: 1;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .table {
      min-width: 640px;

      .table-title {
        th {
          font-weight: bold;
        }
      }
    }
  }
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
}

.user-edit-view {
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .user-edit-paper {
    width: 90%;
    max-width: 960px;
    min-height: 480px;
    padding: 24px;
    margin-bottom: 48px;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    
    .MuiTypography-h6 {
      margin-top: 12px;
      margin-bottom: 8px;
    }

  .user-edit-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    flex: 3;
    max-height: 480px;
    max-width: 1020px;

      .user-edit-first {
        display: flex;
        flex: 1;
        flex-direction: column;


        .user-edit-first-image-container {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          max-width: 240px;
          min-width: 180px;
          max-height: 360px;
          background: #ececec;
          position: relative;

          .user-edit-first-edit-profileImage {
            position: absolute;
            top: 0px;
            right: 0px;
          }


          .user-edit-profile-image {
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            max-width: 180px;
            max-height: 240px;
          }
        }

        .user-edit-profile-image-edit-view {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 16px;
          .user-edit-profile-image-edit-view-url {
            // max-width: 180px;
            // overflow-x: auto;
            // height: 48px;
          }
        }
      }

      .user-edit-second {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        // margin-left: 32px;
        // margin-right: 32px;
        margin-left: 8px;
        margin-right: 8px;

        .user-edit-second-top {
          flex-direction: column;
          display: flex;
          width: 80%;

          .user-edit-second-top-item {
            margin-bottom: 16px;
          }
        }
      }

      .user-edit-third {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;

        .user-edit-third-item-top {

        }

        .user-edit-third-item-bottom {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
